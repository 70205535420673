<template>
  <div>
    <div class="player-wrapper">
      <iframe id="ommaPlayer" :src="getPlayableFactoryUrl" />
    </div>

    <div id="completed">
      <img style="width: 150px;" src="~@/assets/icons/checkGradient.svg" alt="check" />
      <VueText style="margin-top: 20px;" sizeLevel="6" weightLevel="3">{{
        getPlayableCompletedText
      }}</VueText>
    </div>
  </div>
</template>
<script>
import Playable from '@/services/Api/playable';
import VueText from '@/components/shared/VueText/VueText';
import StorageHelper from '@/utils/storageHelper';
import GameApi from '@/services/Api/gamification';
import RosetteInfo from '@/mixins/rosetteInfo.js';
import Gamification from '@/services/Api/gamification';

export default {
  name: 'WeeklyPlayable',
  mixins: [RosetteInfo],
  components: {
    VueText,
  },
  computed: {
    getPlayableInfo() {
      return new StorageHelper({
        id: 'PlayableInfo',
      }).get();
    },
    getPlayableFactoryUrl() {
      return this.getPlayableInfo.marathonUrl;
    },
    getPlayableCompletedText() {
      return 'Haftanın Bulmacasını Tamamladınız';
    },
  },
  data() {
    return {
      hasActiveMarathons: false,
    };
  },
  async mounted() {
    await this.activeMarathons();
    await this.loadScriptFunc();
    await this.showCompletedDiv();
  },
  methods: {
    async loadScriptFunc() {
      const taskInfo = this.getPlayableInfo;
      const completedDiv = document.getElementById('completed');
      const ommaPlayer = document.getElementById('ommaPlayer');

      window.onmessage = async e => {
        if (e.data.type == 'pf_event') {
          const eventName = e.data.name;
          if (eventName == 'start' || eventName == 'end' || eventName == 'cta') {
            const request = {
              totalDuration: 0,
              playDuration: e.data.time,
              status:
                eventName == 'start' ? 1 : eventName == 'cta' ? 4 : eventName == 'end' ? 3 : 2,
              success: eventName == 'end' ? !!e.data.value : false,
              isLinkClicked: eventName == 'cta' ? true : false,
              taskId: taskInfo?.marathonSurveyId || taskInfo?.id,
              isMarathon: !!taskInfo.marathonId,
            };
            await Playable.playableSaveLog(request).then(async () => {
              if (eventName == 'end') {
                completedDiv.style.display = 'table-column';
                ommaPlayer.remove();
                await this.triggerRefresh();
              }
            });
          }
        }
      };
    },
    async triggerRefresh() {
      await this.getRosettesList();
      await this.getRosetteRewardStatu();
      await this.getMarathon();
    },
    showCompletedDiv() {
      if (!this.hasActiveMarathons) {
        const completedDiv = document.getElementById('completed');
        const ommaPlayer = document.getElementById('ommaPlayer');
        completedDiv.style.display = 'table-column';
        ommaPlayer.remove();
      }
    },
    async activeMarathons() {
      const result = await GameApi.checkActiveMarathons();
      result?.data?.Data?.marathon?.forEach(f => {
        if (f.marathonId === 1012) {
          this.hasActiveMarathons = true;
        }
      });
    },
    async getMarathon() {
      await Gamification.getFeedMarathon()
        .then(res => {
          if (res.data?.Data?.dailyMarathon) {
            const {
              Data: { dailyMarathon },
            } = res.data;
            this.$store.dispatch('app/setCurrentMarathon', dailyMarathon);
          } else {
            this.$store.dispatch('app/setCurrentMarathon', null);
          }
        })
        .catch(() => {
          this.$store.dispatch('app/setCurrentMarathon', null);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#completed {
  display: none;
  text-align: center;
  position: absolute;
  left: 30%;
  top: 30%;
}
.player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  #ommaPlayer {
    position: absolute;
    width: 90%;
    height: 90%;
    left: 5%;
  }
}
</style>
